<template>
    <div>
        <div class="row">
            <div class="col pr-0">
                <el-time-select
                    placeholder="time"
                    v-model="time"
                    @change="changeTime"
                    start="00:00"
                    step="00:15"
                    end="23:45"
                >
                </el-time-select>
            </div>
        </div>
        <div v-if="has_error" role="alert" class="row invalid-feedback d-block">
            <strong>{{ error_message }}</strong>
        </div>
    </div>


</template>
<script>
export default {

    props: ['model'],
    data() {
        return {
            wire: null,
            time: null,
            error_message: null,
            has_error:false
        };
    },
    methods: {
        changeTime() {
            Livewire.find(this.wire).set(this.model, this.time);
        }
    },
    mounted() {
        this.wire = this.$el.closest('div[wire\\:id]').getAttribute('wire:id');
        document.addEventListener('livewire:load', function () {
            let time = Livewire.find(this.wire).get(this.model);
            this.time = time;
        }.bind(this))

        document.addEventListener("DOMContentLoaded", () => {
            Livewire.hook('message.received', (message, component) => {
                if(component.id === this.wire) {
                    let time = Livewire.find(this.wire).get(this.model);
                    this.time = time;

                    let errors = component.serverMemo.errors;
                    if (errors.hasOwnProperty(this.model)) {
                        this.has_error = true;
                        this.error_message = errors[this.model][0] +"\n";
                    }

                    if (!errors.hasOwnProperty(this.model)) {
                        this.has_error = false;
                        this.error_message = '';
                    }
                }
            });
        });
    }
};
</script>
